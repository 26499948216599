function GoogleTag() {
  googletag.cmd.push(function () {
    var REFRESH_KEY = 'refresh';
    var REFRESH_VALUE = 'true';

    //For 1480x360
    var mapping1 = googletag
      .sizeMapping()
      .addSize([1024, 500], [1480, 360])
      .addSize([768, 432], [728, 90])
      .addSize([470, 732], [300, 100])
      .addSize([360, 400], [300, 100])
      .addSize([0, 0], [300, 100])
      .build();

    //For 300x600
    var mapping2 = googletag
      .sizeMapping()
      .addSize([1024, 500], [300, 600])
      .addSize([768, 432], [])
      .addSize([470, 400], [300, 250])
      .addSize([360, 400], [300, 250])
      .addSize([0, 0], [300, 250])
      .build();

    //For 970x250
    var mapping3 = googletag
      .sizeMapping()
      .addSize([1024, 500], [970, 250])
      .addSize([768, 432], [728, 90])
      .addSize([470, 400], [300, 100])
      .addSize([360, 400], [300, 100])
      .addSize([0, 0], [300, 100])
      .build();

    //For 720x480
    var mapping4 = googletag
      .sizeMapping()
      .addSize([1024, 500], [720, 480])
      .addSize([768, 432], [])
      .addSize([470, 400], [300, 250])
      .addSize([360, 400], [300, 250])
      .addSize([0, 0], [300, 250])
      .build();

    //For 300x250 MR Ad
    var mapping5 = googletag
      .sizeMapping()
      .addSize([1024, 768], [300, 250])
      .addSize([768, 432], [300, 250])
      .addSize([470, 400], [300, 250])
      .addSize([360, 400], [300, 250])
      .addSize([0, 0], [300, 250])
      .build();

    //For Anchor Ad

    var mapping6 = googletag
      .sizeMapping()
      .addSize([1024, 500], [728, 90])
      .addSize([768, 432], [468, 60])
      .addSize([470, 400], [320, 50])
      .addSize([360, 400], [320, 50])
      .addSize([0, 0], [320, 50])
      .build();

    googletag
      .defineSlot('/31570398/NationalJewels/Leaderboard', [[1480, 360]], 'div-gpt-ad-6390982-1')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping1)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/BTYB', [[970, 250]], 'div-gpt-ad-6390982-2')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/TowerAd', [[300, 600]], 'div-gpt-ad-6390982-3')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping2)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/MRAd1', [[300, 250]], 'div-gpt-ad-6390982-4')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping5)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/MRAd2', [[300, 250]], 'div-gpt-ad-6390982-5')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping5)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/MRAd3', [[300, 250]], 'div-gpt-ad-6390982-6')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping5)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/MRAd4', [[300, 250]], 'div-gpt-ad-6390982-15')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping5)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/Billboard1', [[970, 250]], 'div-gpt-ad-6390982-7')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping3)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/Billboard2', [[970, 250]], 'div-gpt-ad-6390982-8')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping3)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/Billboard3', [[970, 250]], 'div-gpt-ad-6390982-9')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping3)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/Billboard4', [[970, 250]], 'div-gpt-ad-6390982-10')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping3)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/Billboard5', [[970, 250]], 'div-gpt-ad-6390982-11')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping3)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/Prestitial_Test', [[720, 480]], 'div-gpt-ad-6390982-12')
      .defineSizeMapping(mapping4)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NJ_Interstitial', [[720, 480]], 'div-gpt-ad-1729278470577-0')
      .defineSizeMapping(mapping4)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/SOTM_Leaderboard', [[1480,360]], 'div-gpt-ad-6390982-13')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping1)
      .addService(googletag.pubads());
    googletag
      .defineSlot('/31570398/NationalJewels/SOTM_Billboard1', [[970,250]], 'div-gpt-ad-6390982-14')
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('test', 'event')
      .defineSizeMapping(mapping3)
      .addService(googletag.pubads());

    // googletag.pubads().disableInitialLoad();

    var SECONDS_TO_WAIT_AFTER_VIEWABILITY = 15;

    googletag.pubads().addEventListener('impressionViewable', function (event) {
      var slot = event.slot;

      if (slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1) {
        setTimeout(function () {
          googletag.pubads().refresh([slot]);
        }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000);
      }
    });
    googletag.pubads().collapseEmptyDivs();
    googletag.enableServices();
  });
}

export default GoogleTag;
