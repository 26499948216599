import $ from 'jquery';
import Cookies from 'js-cookie';

import './dfp.css';

const firstVisit = Cookies.get('firstVisit');
const secondVisit = Cookies.get('secondVisit');
const hostname = 'nationaljeweler.com';

$(document).ready(function () {
  if (!firstVisit) {
    Cookies.remove('secondVisit');

    var closeBtn = $('#dfp-overlay');
    var ppCloseBtn = $('#pp-close');

    setTimeout(function () {
      $('#dfp').addClass('dfp-open');
      $('#pp').addClass('pp-open');
      Cookies.set('firstVisit', false, { expires: 1 });
    }, 400);

    closeBtn.on('click', function () {
      $('#dfp').removeClass('dfp-open');
    });

    setTimeout(function () {
      $('#dfp').removeClass('dfp-open');
    }, 8000);

    ppCloseBtn.on('click', function () {
      $('#pp').remove()
    });
  } else if (!secondVisit) {
    try {
      const prev_url = new URL(document.referrer);
      if (prev_url.hostname != hostname) return;

      var closeBtn = $('#dfp_second-overlay');
      
      setTimeout(function () {
        $('#dfp_second').addClass('dfp-open');
        Cookies.set('secondVisit', false, { expires: 1 });
      }, 400);
  
      closeBtn.on('click', function () {
        $('#dfp_second').removeClass('dfp-open');
      });
  
      setTimeout(function () {
        $('#dfp_second').removeClass('dfp-open');
      }, 8000);
    } catch (_e) {
      return;
    }
  }
});
